import CacheEnum from '@/enums/cacheEnum';
import PageEnum from '@/enums/pageEnum';
import { history } from '@umijs/max';
import { useDocumentVisibility, useMount } from 'ahooks';
import { useEffect, useState } from 'react';

export default () => {
  // 使用useState钩子来管理充值信息的状态
  const [rechargeInformation, setRechargeInformation] = useState<any>();
  // 使用useLocalStorage钩子来管理当前步骤的状态，并设置默认值为0
  // 在组件挂载时，从本地存储中读取充值信息

  const [isFirst, setIsFirst] = useState(true);
  useMount(() => {
    setIsFirst(false);
    setRechargeInformation(
      JSON.parse(
        localStorage.getItem(CacheEnum.APP_RECHARGE_INFORMATION) as any,
      ),
    );
  });
  const documentVisibility: any = useDocumentVisibility();
  //监听用户离开app的事件
  // useEffect(() => {
  //   if (documentVisibility === 'hidden') {
  //   }
  //   if (documentVisibility === 'visible') {
  //     //用户回到app的时候 重新连接下soket
  //     if (!isFirst) {
  //       history.replace(PageEnum.FUNDS);
  //     }
  //   }
  // }, [documentVisibility]);
  // 返回相关状态和函数供父组件使用
  return {
    rechargeInformation, // 充值信息
  };
};
