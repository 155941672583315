import { F7ExclamationmarkCircleFill } from '@/assets/icons/comm/F7ExclamationmarkCircleFill';
import { MaterialSymbolsArrowBack } from '@/assets/icons/layout/MaterialSymbolsArrowBack';
import ThreePartyRechargeInformation from '@/pages/funds/threePartyRecharge/components/ThreePartyRechargeInformation';
import { history } from '@@/core/history';
import { Divider } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import useStateHooks from './useStateHooks';

/**
 * 充值进度页面
 */
export default () => {
  const {
    rechargeInformation, // 充值信息
  } = useStateHooks();
  const { t } = useTranslation();
  return (
    <div className="min-h-[100vh] pt-4 ">
      <div className="overflow-hidden">
        <div className="pt-4 px-4 flex items-center">
          <MaterialSymbolsArrowBack
            onClick={() => {
              history.back();
            }}
            className="text-xl font-bold"
          />

          <div className="text-2xl ml-3"> {t('订单已生成')}</div>
        </div>
        <Divider className="my-2 !bg-backgroundAuxiliaryColor" />
        <div className="px-4">
          <div className="bg-primary/20  mt-3 text-foreground flex justify-between text-xs  rounded-[14px] px-4 py-2 leading-[28px]">
            <div className="flex items-center">
              <F7ExclamationmarkCircleFill
                width="1.2em"
                height="1.2em"
                className="mr-2"
              />
              {t('为了您的资金安全，请进行信息核对后充值。')}
            </div>
          </div>
        </div>
        <div className="px-4 pt-5 flex flex-col  gap-3">
          {/* 交易方式选择 */}
          <div className="flex flex-col gap-2">
            <div className="text-auxiliaryTextColor"> {t('交易方式')}</div>
            {/*第三方充值*/}
            <div className="text-backContrastColo flex items-center  mb-4 ">
              <span className="h-[10px] w-[2px] bg-primary mr-2"></span>
              <span className="text-sm text-backContrastColor">
                {t('加密货币充值')}
              </span>
            </div>
          </div>
          {/* C2C第二信息栏，展示和编辑支付详情 */}
          <ThreePartyRechargeInformation
            rechargeInformation={rechargeInformation}
          />
        </div>
      </div>
    </div>
  );
};
