import { PhCopyFill } from '@/assets/icons/mine/PhCopyFill';
import CacheEnum from '@/enums/cacheEnum';
import PageEnum from '@/enums/pageEnum';
import { FormatUtils } from '@/utils/format';
import { history } from '@@/core/history';
import { useModel } from '@@/exports';
import { Button, Divider } from '@nextui-org/react';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';

/**
 * 三方充值
 * @props rechargeInformation 充值信息，包含银行卡等详情
 * @returns 返回JSX元素，展示充值确认界面
 */
export default ({ rechargeInformation }: IProps) => {
  const { t } = useTranslation(); // 使用国际化库，便于多语言支持

  const isXrp = rechargeInformation?.virtualNetworkName === 'XRP';

  const { user } = useModel('user');

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-2">
        <div className="flex justify-between">
          <span className="text-auxiliaryTextColor">{t('地址')}</span>{' '}
          {/* 显示U盾地址 */}
        </div>
        <div className="flex items-center gap-1 text-backContrastColor break-words text-sm">
          {rechargeInformation?.udunAddress} {/* 显示完整的银行卡号 */}
          <PhCopyFill
            onClick={() =>
              FormatUtils.copyText(rechargeInformation?.udunAddress)
            }
            className="text-auxiliaryTextColor text-2xl cursor-pointer"
          />
        </div>
      </div>

      {isXrp && (
        <div className="flex flex-col gap-2">
          <div className="flex justify-between">
            <span className="text-auxiliaryTextColor">{t('用户ID')}</span>{' '}
            {/* 显示U盾地址 */}
          </div>
          <div className="text-auxiliaryTextColor text-[11px]">
            {t('充值时请将以下ID复制粘贴到备注栏，以便快速处理您的订单')}
          </div>
          <div className="flex items-center gap-1 text-backContrastColor break-words text-sm">
            {user?.account} {/* 显示完整的银行卡号 */}
            <PhCopyFill
              onClick={() => FormatUtils.copyText(user?.account)}
              className="text-auxiliaryTextColor text-2xl cursor-pointer"
            />
          </div>
        </div>
      )}

      <Divider className="my-4 !bg-backgroundAuxiliaryColor" />

      <div className="mt-4 mb-1">
        <span className="text-auxiliaryTextColor">{t('二维码支付')}</span>{' '}
        {/* 显示付款记录截图证明的提示 */}
      </div>
      <div className="flex justify-center  w-full">
        <QRCode
          value={rechargeInformation?.udunAddress}
          style={{ width: '200px', height: '200px' }}
        />
      </div>

      <div className="flex items-center  bg-background  w-full mt-7">
        <Button
          className="mainColorButton flex-1 !bg-backgroundAuxiliaryColor !text-foreground mr-1"
          onClick={() => {
            localStorage.removeItem(CacheEnum.APP_C2C_ORDER_STEPS);
            history.replace(PageEnum.TopUp); // 取消订单，清除缓存并跳转回C2C页面
          }}
        >
          {t('返回')}
        </Button>
      </div>
    </div>
  );
};

interface IProps {
  rechargeInformation: any; // 充值信息，结构较复杂，包含银行卡等详情
}
